<template>
  <div class="head_top">
    <div class="search">
      <div class="searchBox">
        <span>欢迎来到智驹技术支持</span>
        <p>需要帮助?</p>
        <p>搜索产品型号或者搜索您的问题.</p>
        <el-select style="width: 400px" v-model="value" filterable @change="change" placeholder="请输入查询的设备">
          <el-option
              v-for="item in options"
              :key="item.label"
              :value="item.label">
          </el-option>
        </el-select>
      </div>
    </div>
    <!--    <img src="../../../../public/images/二级海报/技术支持.jpg" width="100%" height="100%" lazy>-->
    <div class="font_center">VStarcam技术支持</div>
    <div class="font_center2">请选择产品型号</div>
    <!--    content1 网络摄像机（室内）-->
    <div class="content1">
      <div>
        <div class="font_center3">网络摄像机（室内)</div>
        <div class="flex">
          <figure class="imghvr-zoom-in" @click="C48Q">
            <img src="../../../../public/images/官网底部文字对应的二级页面/技术支持/样例产品.jpg" width="100%" height="100%" lazy>
            <figcaption style="text-align: center;font-size: 24px;line-height:285px;">
              C48Q
            </figcaption>
          </figure>
          <figure class="imghvr-zoom-out-flip-vert">
            <img src="../../../../public/images/官网底部文字对应的二级页面/技术支持/样例产品.jpg" width="100%" height="100%" lazy>
            <figcaption>
              C48Q
            </figcaption>
          </figure>
          <figure class="imghvr-reveal-up">
            <img src="../../../../public/images/官网底部文字对应的二级页面/技术支持/样例产品.jpg" width="100%" height="100%" lazy>
            <figcaption>
              C48Q
            </figcaption>
          </figure>
          <figure class="imghvr-reveal-up">
            <img src="../../../../public/images/官网底部文字对应的二级页面/技术支持/样例产品.jpg" width="100%" height="100%" lazy>
            <figcaption>
              C48Q
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
    <!--    content1 网络摄像机（室外）-->
    <div class="content1">
      <div>
        <div class="font_center3">网络摄像机（室外)</div>
        <div class="flex">
          <figure class="imghvr-zoom-in">
            <img src="../../../../public/images/官网底部文字对应的二级页面/技术支持/样例产品.jpg" width="100%" height="100%" lazy>
            <figcaption style="text-align: center;font-size: 24px;line-height:285px;">
              C48Q
            </figcaption>
          </figure>
          <figure class="imghvr-zoom-out-flip-vert">
            <img src="../../../../public/images/官网底部文字对应的二级页面/技术支持/样例产品.jpg" width="100%" height="100%" lazy>
            <figcaption>
              C48Q
            </figcaption>
          </figure>
          <figure class="imghvr-shutter-in-out-vert">
            <img src="../../../../public/images/官网底部文字对应的二级页面/技术支持/样例产品.jpg" width="100%" height="100%" lazy>
            <figcaption>
              C48Q
            </figcaption>
          </figure>
          <figure class="imghvr-zoom-out-right">
            <img src="../../../../public/images/官网底部文字对应的二级页面/技术支持/样例产品.jpg" width="100%" height="100%" lazy>
            <figcaption>
              C48Q
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "技术支持",
  data() {
    return {
      options: [
        {
          label: 'C48Q'
        }, {
          label: 'C48Q123'
        },
        {
          label: 'asd'
        },
        {
          label: 'zxc'
        },
        {
          label: 'qwe'
        },
        {
          label: 'rew'
        },
        {
          label: 'rew'
        },
        {
          label: 'rew'
        },
        {
          label: 'rew'
        }
      ],
      value: ''
    }
  },
  methods: {
    change(label) {
      this.$router.push('/tsindoor' + label)
      console.log(label)
    },
    C48Q() {
      this.$router.push('/tsindoorC48Q')
    }

  }
}
</script>

<style scoped>
.flex{
  display:flex;
  justify-content: space-around;
}
.font_center {
  font-size: 32px;
  text-align: center;
}

.font_center2 {
  text-align: center;
  font-size: 19px;
}

.font_center3 {
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
}

.content1 {
  margin: auto;
  width: 80%;
  /*height:*/
}

.search {
  width: 100%;
  height: 650px;
  background: url("../../../../public/images/二级海报/技术支持.jpg") no-repeat;
}

.searchBox {
  padding-top: 100px;
  color: white;
  text-align: center;

}

span:nth-child(1) {
  font-size: 36px;
}

[class*=" imghvr-"], [class^=imghvr-] {
  background-color: #e33434;
}

[class*=" imghvr-"] figcaption, [class^=imghvr-] figcaption {
  background-color: #e33434;
}

[class*=" imghvr-reveal-"]:before, [class^=imghvr-reveal-]:before {
  background-color: #e33434;
}

[class*=" imghvr-shutter-in-"]:after, [class*=" imghvr-shutter-in-"]:before, [class^=imghvr-shutter-in-]:after, [class^=imghvr-shutter-in-]:before {
  background-color: #e33434;
}
</style>